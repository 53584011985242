import { BackendApi, WttRestResponseBody } from '../backend-api';
import { DefaultApiErrorResponse, isApiResponse } from '../api';
import { PasswordChangeResponse } from './password-change-response';
import { PasswordResetInitResponse } from './password-init-reset-response';
import {
  ConfigData,
  ConfigType,
  CreateOrUpdateUserSettingErrorResponse,
  CreateOrUpdateUserSettingResponse,
  UserSettingDeleteResponse,
  UserSettingErrorResponse,
  UserSettingModel,
  UserSettingRequest,
  UserSettingResponse,
  UserSettingsResponse,
} from './user-settings-request-response';
import { WttSelectItem } from '../../components/lib/wtt-select/wtt-select';

export class UserApi extends BackendApi {
  private readonly BASE_PATH = '/api/v1/user';
  private readonly SETTINGS_BASE_PATH = `${this.BASE_PATH}/me/settings`;

  async deleteFile(id: string): Promise<DownloadResult> {
    let res = await this.fetch('DELETE', `${this.BASE_PATH}/my/downloads/${ id }`, {
      headers: {
        Accept: '*/*'
      }
    })
      .catch(() => null);

    if (!res) {
      return {status: 'UNKNOWN_ERROR', message: 'unknown error'};
    }

    if (res.status === 202) {
      return {status: 'OK', message: ''};
    } else if (res.status === 404) {
      return {status: 'NOT_FOUND', message: 'The requested file could not be found'};
    } else {
      return {status: 'UNKNOWN_ERROR', message: res.statusText};
    }
  }

  /**
   * Start the download of a listed file from the mongo
   * @param id hex ID of the file
   * @param fileName filename
   */
  async downloadFile(id: string, fileName: string): Promise<DownloadResult> {
    let res = await this.fetch('GET', `${this.BASE_PATH}/my/downloads/${ id }`, {
      headers: {
        Accept: '*/*'
      }
    })
      .catch(() => null);

    if (!res) {
      return {status: 'UNKNOWN_ERROR', message: 'unknown error'};
    }

    /*
     * file download for the poor: this imitates a click on a blob link ...
     */
    return await this.genericFileDownload(res, fileName);

  }

  public async genericFileDownload(res: Response, fileName: string): Promise<DownloadResult> {
    if (res.status === 200) {
      const blob = await res.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      return {status: 'OK', message: ''};
    } else if (res.status === 404) {
      return {status: 'NOT_FOUND', message: 'The requested file could not be found'};
    } else {
      return {status: 'UNKNOWN_ERROR', message: res.statusText};
    }
  }

  /**
   * List downloads for the given user from mongo
   */
  async getMyDownloads(): Promise<DownloadsResponse> {
    const response = await this.fetch('GET', `${this.BASE_PATH}/my/downloads`)
      .catch(() => null);

    if (!response) {
      return {status: 'CONNECTION_ERROR'};
    }

    if (!response.ok) {
      if (response.status === 403) {
        return {status: 'UNAUTHENTICATED'};
      }

      return {status: 'UNKNOWN_ERROR'};
    }

    const responseBody = await response.json() as WttRestResponseBody<DownloadItem[]>;
    return {
      status: 'OK',
      data: responseBody.data,
    };
  }

  /**
   * get the user data of the current logged in user
   */
  async getPresets(): Promise<PresetsResponse> {
    const response = await this.fetch('GET', `${this.BASE_PATH}/presets`)
      .catch(() => null);

    if (!response) {
      return {status: 'CONNECTION_ERROR'};
    }

    if (!response.ok) {
      if (response.status === 403) {
        return {status: 'UNAUTHENTICATED'};
      }

      return {status: 'UNKNOWN_ERROR'};
    }

    const responseBody = await response.json() as WttRestResponseBody<User24Presets>;
    return {
      status: 'OK',
      presets: responseBody.data
    };
  }

  /**
   * get the user data of the current logged in user
   */
  async getMe(): Promise<MeResponse> {
    const response = await this.fetch('GET', `${this.BASE_PATH}/me`)
      .catch(() => null);

    if (!response) {
      return {status: 'CONNECTION_ERROR'};
    }

    if (!response.ok) {
      if (response.status === 403) {
        return {status: 'UNAUTHENTICATED'};
      }

      return {status: 'UNKNOWN_ERROR'};
    }

    const responseBody = await response.json() as WttRestResponseBody<Me>;
    return {
      status: 'OK',
      user: responseBody.data,
    };
  }

  /**
   * Save the form data of user information back to user24 via backend
   *
   * @param myData user data
   */
  async saveMe(myData: userData): Promise<MeResponse> {
    const response = await this.fetch('PATCH', `${this.BASE_PATH}/me`,
      {body: myData})
      .catch(() => null);

    if (!response) {
      return {status: 'CONNECTION_ERROR'};
    }

    const responseBody = await response.json() as WttRestResponseBody<Me>;

    if (!response.ok) {
      if (response.status === 403) {
        return {status: 'UNAUTHENTICATED', body: responseBody};
      }

      // @ts-ignore
      return {status: 'UNKNOWN_ERROR', body: responseBody};
    }

    return {
      status: 'OK',
      user: responseBody.data,
    };
  }

  async initResetPassword(accountLogin: string, userLogin: string): Promise<PasswordResetInitResponse> {
    const response = await this.fetch('POST', `${this.BASE_PATH}/password/reset/init`, {
      body: {
        'accountLogin': accountLogin,
        'userLogin': userLogin,
      },
      sendTokenHeader: false,
    })
      .catch(() => null);

    if (!response) {
      return {status: 'CONNECTION_ERROR'};
    }

    if (!response.ok) {
      return {status: 'UNKNOWN_ERROR'};
    }

    return {status: 'OK'};
  }

  async resetPassword(token: string, newPassword: string): Promise<PasswordChangeResponse> {
    return this.handlePasswordChange(
      this.fetch('POST', `${this.BASE_PATH}/password/reset`, {
        body: {
          'uuid': token,
          'newPassword': newPassword,
        },
        sendTokenHeader: false,
      }),
    );
  }

  async changePassword(currentPassword: string, newPassword: string): Promise<PasswordChangeResponse> {
    return this.handlePasswordChange(
      this.fetch('PATCH', `${this.BASE_PATH}/me/password`, {
        body: {
          'oldPassword': currentPassword,
          'newPassword': newPassword,
        },
      }),
    );
  }

  private async handlePasswordChange(fetchPromise: Promise<Response>): Promise<PasswordChangeResponse> {
    const response = await this.mapGeneralErrors<PasswordChangeResponse>(
      fetchPromise,
      async notOkResponse => {
        const notOkResponseBody = await notOkResponse.json() as WttRestResponseBody<PasswordResetValidation>;
        const data = notOkResponseBody.data;

        if (data && !data.success) {
          if (notOkResponse.status === 404) {
            return {status: 'NOT_FOUND'};
          }

          if (notOkResponse.status === 400) {
            return {
              status: 'VALIDATION_FAILED',
              validationErrors: data.validationErrors,
            };
          }
        }
      },
    );

    if (isApiResponse(response)) {
      return response;
    }

    return {status: 'OK'};
  }

  async getMyUserSettings(configType: ConfigType): Promise<UserSettingsResponse> {
    const response = await this.mapGeneralErrors<UserSettingsResponse>(
      this.fetch('GET', `${this.SETTINGS_BASE_PATH}/${configType}`),
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<UserSettingModel[]>;

    return {
      status: 'OK',
      userSettings: responseBody.data,
    };
  }

  async getUserSettingById(configType: ConfigType, id: number): Promise<UserSettingResponse> {
    const response = await this.mapGeneralErrors<UserSettingResponse>(
      this.fetch('GET', `${this.SETTINGS_BASE_PATH}/${configType}/id/${id}`),
      async notOkResponse => this.handleUserSettingError(notOkResponse),
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<UserSettingModel>;

    return {status: 'OK', userSetting: responseBody.data};
  }

  async getUserSettingByName(configType: ConfigType, name: string): Promise<UserSettingResponse> {
    const response = await this.mapGeneralErrors<UserSettingResponse>(
      this.fetch('GET', `${this.SETTINGS_BASE_PATH}/${configType}/name/${name}`),
      async notOkResponse => this.handleUserSettingError(notOkResponse),
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<UserSettingModel>;

    return {status: 'OK', userSetting: responseBody.data};
  }

  async deleteUserSettingById(configType: ConfigType, id: number): Promise<UserSettingDeleteResponse> {
    const response = await this.mapGeneralErrors<UserSettingDeleteResponse>(
      this.fetch('DELETE', `${this.SETTINGS_BASE_PATH}/${configType}/id/${id}`),
      async notOkResponse => this.handleDeleteUserSettingError(notOkResponse),
    );

    if (isApiResponse(response)) {
      return response;
    }

    return {status: 'ACCEPTED'};
  }

  async createOrUpdateUserSetting(configType: ConfigType, name: string, description: string, configData: ConfigData, version: number, active: boolean, id?: number): Promise<CreateOrUpdateUserSettingResponse> {
    const requestBody: UserSettingRequest = {
      configType, name,
      description,
      configData, version, active, ...(id && {id})
    };
    const response = await this.mapGeneralErrors<CreateOrUpdateUserSettingResponse>(
      this.fetch('POST', `${this.SETTINGS_BASE_PATH}/${configType}`, {body: {...requestBody}}),
      async notOkResponse => this.handleCreateOrUpdateUserSettingError(notOkResponse),
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<UserSettingModel>;

    return {status: 'OK', userSetting: responseBody.data};
  }

  private async handleUserSettingError(notOkResponse: Response): Promise<UserSettingErrorResponse | undefined> {
    if (notOkResponse.status !== 200) {
      const notOkResponseBody = await notOkResponse.json();
      switch (notOkResponseBody.responseCode) {
        case 10404:
          return {status: 'NOT_FOUND'};
      }
    }
  }

  private async handleDeleteUserSettingError(notOkResponse: Response): Promise<UserSettingErrorResponse | undefined> {
    if (notOkResponse.status !== 202) {
      const notOkResponseBody = await notOkResponse.json();
      switch (notOkResponseBody.responseCode) {
        case 10515:
          return {status: 'NOT_FOUND'};
      }
    }
  }


  private async handleCreateOrUpdateUserSettingError(notOkResponse: Response): Promise<CreateOrUpdateUserSettingErrorResponse | undefined> {
    if (notOkResponse.status !== 200) {
      const notOkResponseBody = await notOkResponse.json();
      switch (notOkResponseBody.responseCode) {
        case 10404:
          return {status: 'NOT_FOUND'};
        case 10100:
          return {status: 'CONFLICT'};
      }
    }
  }

}

export const userApi = new UserApi();

export type PresetsResponse = PresetsSuccessResponse | PresetsErrorResponse | DefaultApiErrorResponse;
export type MeResponse = MeSuccessResponse | MeErrorResponse | DefaultApiErrorResponse;
export type DownloadsResponse = DlSuccessResponse | DlErrorResponse | DefaultApiErrorResponse;

export interface DlSuccessResponse {
  status: 'OK';
  data: DownloadItem[];
}

export interface DownloadItem {
  workbookName: string
  owner: string,
  uploadDate: string,
  fileSize: number,
  id: string,
  exported_rows: number,
  contentType: string,
  account: string,
  export_expireAt: string
}

export interface DownloadResult {
  status: 'OK' | 'NOT_FOUND' | 'UNKNOWN_ERROR';
  message: string;
}

export interface MeSuccessResponse {
  status: 'OK';
  user: Me;
}

export interface DlErrorResponse {
  status: 'UNAUTHENTICATED';
}

export interface MeErrorResponse {
  status: 'UNAUTHENTICATED';
  body: WttRestResponseBody<Me>;
}

export interface PresetsSuccessResponse {
  status: 'OK';
  presets: User24Presets;
}

export interface PresetsErrorResponse {
  status: 'UNAUTHENTICATED';
  body: WttRestResponseBody<User24Presets>;
}

export interface Me {
  login: string,
  status: any,
  firstName: string,
  lastName: string,
  title: string,
  gender: any,
  position: string,
  department: string,
  phone: string,
  email: string,
  language: string,
  secondaryLanguages: string[],
  timezone: string,
  twoFactorAuthType: any
}

export class userData {
  firstName: string = "";
  lastName: string = "";
  title: string = "";
  gender: ("MALE" | "FEMALE" | "UNDEFINED") = "UNDEFINED";
  position: string = "";
  department: string = "";
  phone: string = "";
  language: string = "de";
}

export class Presets {
  gender: WttSelectItem[] = [];
  languages: WttSelectItem[] = [];
}

export class User24Presets {
  status: string | undefined;
  gender: string[] = [];
  languages: string[] = [];
}

interface PasswordResetValidation {
  success: boolean,
  validationErrors: PasswordValidationError[]
}

export interface PasswordValidationError {
  type: 'CURRENT_PASSWORD_WRONG' | 'POLICY_VIOLATION' | 'HISTORY_VIOLATION',
  localizedDescription?: string
}
