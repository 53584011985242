import {Config, getConfig} from '../config';
import _ from 'lodash';
import {CONNECTION_ERROR_TOAST, toastService} from '../components/lib/toast/toast-service';
import {navigate} from '../components/common/routing';
import {isNonNil} from '../utils';
import {authState, WttServiceGrant} from './auth/auth-state';
import {persistenceService} from './persistence-service';

export const defaultFetchOptions: FetchOptions = {
  queryParams: {},
  headers: {},
  body: undefined,
  automaticBodyJsonConversion: true,
  sendTokenHeader: true,
  keepalive: undefined,
};

export const TOKEN_HEADER: string = "x-wtt-token";
export type RefreshStatus = 'SUCCESS' | 'LOGOUT' | 'RETRY';

export abstract class Api {
  static isAuthTokenRefreshing: boolean = false;
  static authTokenRefreshPromise: Promise<RefreshStatus> | undefined = undefined;

  config: Config;

  constructor(config?: Config) {
    this.config = config || getConfig();
  }

  protected abstract baseUrl(): string;

  /**
   * Executes a request to the given endpoint with given options.
   * @param method request method
   * @param endpoint endpoint to call
   * @param options request options; attributes that are not set default to the values of {@link defaultFetchOptions}.
   */
  protected async fetch(
    method: HttpMethod,
    endpoint: string,
    options?: Partial<FetchOptions>,
  ): Promise<Response> {
    await Api.authTokenRefreshPromise;
    const fetchOptions = {
      ...defaultFetchOptions,
      ...options,
    };

    const filteredQueryParams = _.omitBy(fetchOptions.queryParams, _.isUndefined);
    const urlSearchParams = !_.isEmpty(filteredQueryParams) ? `?${new URLSearchParams(filteredQueryParams).toString()}` : '';
    const url = `${this.baseUrl()}${endpoint}${urlSearchParams}`;

    let headers: HeadersInit = {
      ...fetchOptions.headers,
    };

    if (!headers['Content-Type'] && fetchOptions.automaticBodyJsonConversion) {
      headers['Content-Type'] = 'application/json';
    }

    if (!headers['Accept']) {
      headers['Accept'] = 'application/json';
    }

    const authToken = getAuthToken();
    if (fetchOptions.sendTokenHeader && authToken) {
      headers = {
        ...headers,
        'Authorization': `Bearer ${authToken}`,
      };
    }

    let responsePromise = fetch(url, {
      method,
      headers,
      body: fetchOptions.automaticBodyJsonConversion ? JSON.stringify(fetchOptions.body) : fetchOptions.body,
      keepalive: fetchOptions.keepalive,
    });

    responsePromise.then((response) => {
      if (response.headers.has(TOKEN_HEADER)) {
        let token = response.headers.get(TOKEN_HEADER);
        token && setAuthToken(token);
      }
    })
    return responsePromise;
  }

  /**
   * Handles default API errors and returns either the original {@link Response} or a {@link ApiResponse} in case an error
   * occurred. Custom error handling can be achieved by providing a {@link notOkResponseHandler}.
   * @param fetchPromise Promise returned by {@link Api#fetch}.
   * @param notOkResponseHandler Custom handler that can
   * <ul>
   *   <li>return a {@link ApiResponse} in case an error was caught</li>
   *   <li>null in case the response is valid and no error should be returned by this function</li>
   *   <li>undefined in case the handler could not specify the error and the default error handling should take over.</li>
   *   </ul>
   * @return Returns
   * <ul>
   *   <li>original {@link Response} when no error was found or  {@link notOkResponseHandler} returns null</li>
   *   <li>'CONNECTION_ERROR' when {@link fetchPromise} throws an error</li>
   *   <li>'UNAUTHENTICATED' when response status is 403 and {@link notOkResponseHandler} does not return null</li>
   *   <li>'UNKNOWN_ERROR' when response is not ok or {@link notOkResponseHandler} throws an error.
   * </ul>
   */
  protected async mapGeneralErrors<T extends ApiResponse>(
    fetchPromise: Promise<Response>,
    notOkResponseHandler: (response: Response) => Promise<T | null | undefined> = () => {
      return Promise.resolve(undefined);
    },
  ): Promise<T | DefaultApiErrorResponse | Response> {
    const response = await fetchPromise.catch(() => null);

    if (!response) {
      return {status: 'CONNECTION_ERROR'};
    }

    toastService.hideToast(CONNECTION_ERROR_TOAST);

    if (!response.ok) {
      let errorResponse: T | DefaultApiErrorResponse | null | undefined = undefined;

      try {
        errorResponse = await notOkResponseHandler(response)
          .catch(() => {
            return {
              status: 'UNKNOWN_ERROR',
              originalResponse: response,
            };
          });
      } catch (_error) {
        return {status: 'UNKNOWN_ERROR'};
      }

      if (errorResponse === null) {
        return response;
      } else if (errorResponse !== undefined) {
        return errorResponse;
      }

      if (response.status === 403) {
        return {status: 'FORBIDDEN'};
      }

      if (response.status === 401) {
        return {status: 'UNAUTHENTICATED'};
      }

      return {
        status: 'UNKNOWN_ERROR',
        originalResponse: response,
      };
    }

    return response;
  }

}

export type HttpMethod = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'PATCH'

export interface FetchOptions {
  queryParams: Record<string, any>,
  headers: Record<string, any>,
  body: any,
  automaticBodyJsonConversion: boolean,
  sendTokenHeader: boolean,
  keepalive: boolean | undefined
}

export interface ApiResponse {
  status: string;
}

export type DefaultApiErrorResponse =
  UnauthenticatedErrorResponse
  | ConnectionErrorResponse
  | UnknownErrorResponse
  | ForbiddenErrorResponse

export interface UnauthenticatedErrorResponse extends ApiResponse {
  status: 'UNAUTHENTICATED';
}

export interface ConnectionErrorResponse extends ApiResponse {
  status: 'CONNECTION_ERROR';
}

export interface UnknownErrorResponse extends ApiResponse {
  status: 'UNKNOWN_ERROR';
  originalResponse?: Response;
}

export interface ForbiddenErrorResponse extends ApiResponse {
  status: 'FORBIDDEN';
}

export interface ResolvedErrorResponse extends ApiResponse {
  status: 'RESOLVED_ERROR';
}

export async function handleDefaultApiErrors<T extends ApiResponse>(
  response: T | DefaultApiErrorResponse | Promise<T | DefaultApiErrorResponse>,
): Promise<T | ResolvedErrorResponse> {
  const resolvedResponse = await response;

  if (!isDefaultApiError(resolvedResponse)) {
    return resolvedResponse;
  }

  switch (resolvedResponse.status) {
    case 'UNAUTHENTICATED':
      toastService.showToast({
        type: 'error',
        message: {key: 'Unauthenticated! Redirecting to login!'},
      });
      closeOpenDialog();
      clearAuthToken();
      authState.forgetMe();
      navigate('/');
      break;

    case 'CONNECTION_ERROR':
      toastService.showToast({
        type: 'error',
        message: {key: 'Connection error! Please try again later!'},
      });
      break;

    case 'FORBIDDEN':
      toastService.showToast({
        type: 'error',
        message: {key: 'Attempted to perform a prohibited action and failed!'},
      });
      break;

    default : {
      if (resolvedResponse.originalResponse) {
        resolvedResponse.originalResponse.json()
          .then(r =>
            toastService.showToast({
              type: 'error',
              message: {key: 'An error occurred: {message} [{code}]', args: {message: r.responseMessage, code: r.responseCode}},
            }),
          )
          .catch(() =>
            toastService.showToast({
              type: 'error',
              message: {key: 'An error occurred'},
            }),
          );
      } else {
        toastService.showToast({
          type: 'error',
          message: {key: 'An error occurred'},
        });
      }

      break;
    }
  }

  return {status: 'RESOLVED_ERROR'};
}

/**
 * Closes an open dialog.
 */
function closeOpenDialog() {
  window.dispatchEvent(new CustomEvent('wtt.dialog.close'));
}

/**
 * Type guard for {@link ApiResponse}
 * @param o
 */
export function isApiResponse<T extends ApiResponse>(o: Response | T): o is T {
  return o.hasOwnProperty('status') && typeof o.status === 'string';
}

/**
 * Type guard for {@link DefaultApiErrorResponse}
 * @param o
 */
export function isDefaultApiError(o: ApiResponse): o is DefaultApiErrorResponse {
  return o.status === 'UNAUTHENTICATED'
    || o.status === 'CONNECTION_ERROR'
    || o.status === 'UNKNOWN_ERROR'
    || o.status === 'FORBIDDEN';
}

export type DefaultApiErrors = 'UNAUTHENTICATED' | 'CONNECTION_ERROR' | 'UNKNOWN_ERROR' | 'FORBIDDEN';

const AUTH_TOKEN_KEY = 'auth-token';

export function getAuthToken() {
  return persistenceService.getPersistedValue(AUTH_TOKEN_KEY);
}

export function setAuthToken(jwt: string) {
  persistenceService.persistValue(AUTH_TOKEN_KEY, jwt);
  setGrants(jwt);
}

export function setGrants(jwt: string) {
  const parsedJwt = parseJwt<{
    groups: string[]
  }>(jwt);
  authState.grants = parsedJwt.groups
    .map(group => WttServiceGrant.parse(group))
    .filter(isNonNil);
}

export function clearAuthToken() {
  persistenceService.removePersistedValue(AUTH_TOKEN_KEY);
}

export function parseJwt<T>(jwt: string): T {
  return JSON.parse(atob(jwt.split('.')[1]));
}
