import { Api, DefaultApiErrorResponse } from '../api';
import { DownloadResult, userApi } from '../user/user-api';

export class LocalizationApi extends Api {

  baseUrl(): string {
    return location.protocol + '//' + location.host + this.config.localizationPath;
  }

  protected BASE_PATH: string = '/api/v1/localization';

  /**
   * get the release notes as PDF for download
   * Release notes are currently not language dependant, languages should be English entirely.
   */
  async getReleaseNotesAsPdf(): Promise<DownloadResult> {
    let res = await this.fetch('GET', `${this.BASE_PATH}/releasenotes/pdf`, {
      sendTokenHeader: false,
      headers: {
        Accept: '*/*'
      }
    })
      .catch(() => null);

    if (!res) {
      return {status: 'UNKNOWN_ERROR', message: 'unknown error'};
    }

    /*
     * file download for the poor: this imitates a click on a blob link ...
     */
    return await userApi.genericFileDownload(res, 'VADR-ReleaseNotes.pdf');

  }

  /**
   * get the release notes as html. This returns a html fragment, not an entire page!
   * Release notes are currently not language dependant, languages should be English entirely.
   */
  async getReleaseNotesAsHtml(): Promise<ReleasenotesResponse> {
    const response = await this.fetch('GET', `${this.BASE_PATH}/releasenotes/html`, {
      headers: {
        Accept: '*/*'
      },
      sendTokenHeader: false
    })
      .catch(() => null);

    console.log("response", response);

    if (!response) {
      console.warn('Localization API did not respond');
      return {status: 'CONNECTION_ERROR'};
    }

    if (!response.ok) {
      console.warn('Localization API responded with unknown error');
      return {status: 'UNKNOWN_ERROR'};
    }

    const responseBody = await response.text();
    return {
      status: 'OK',
      data: responseBody
    };
  }

  async getLocalizations(language: string): Promise<LocalizationResponse> {
    const response = await this.fetch('GET', `${this.BASE_PATH}/${language}`)
      .catch(() => null);

    if (!response) {
      console.warn('Localization API did not respond');
      return {status: 'CONNECTION_ERROR'};
    }

    if (!response.ok) {
      console.warn('Localization API responded with unknown error');
      return {status: 'UNKNOWN_ERROR'};
    }

    const responseBody = await response.json();
    return {
      status: 'SUCCESS',
      ...responseBody
    };
  }

  async getContextHelp(language: string, context: string): Promise<string> {
    const response = await this.fetch(
      'GET',
      `${this.BASE_PATH}/${language}/${context}`,
      {
        headers: {
          'Accept': 'text/html'
        }
      }).catch(() => null);

    if (!response || !response.ok) {
      return '';
    }

    return response.text()
  }

  getContextHelpUrl(language: string, context: string): string {
    return `${this.baseUrl()}${this.BASE_PATH}/${language}/${context}`
  }
}

export const localizationApi = new LocalizationApi();

export type LocalizationResponse = LocalizationSuccessResponse | DefaultApiErrorResponse;
export type ReleasenotesResponse = ReleasenotesSuccessResponse | ReleasenotesErrorResponse | DefaultApiErrorResponse;

export interface ReleasenotesSuccessResponse {
  status: 'OK';
  data: string;
}

export interface ReleasenotesErrorResponse {
  status: 'NOT_FOUND' | 'NOT_IMPLEMENTED' | 'UNAUTHENTICATED';
}

export interface Releasenotes {
  data: string
}

export interface LocalizationSuccessResponse {
  status: 'SUCCESS';
  keys: Record<string, string>;
  format: Format;
}

interface Format {
  locale: string;
  formats: Formats;
}

interface Formats {
  float: NumberFormat;
  long: NumberFormat;
  currency: CurrencyFormat;
  date: string;
  time: string;
  hour: string;
  datetime: string;
  timestamp: string;
}

interface NumberFormat {
  minimumIntegerDigits: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

interface CurrencyFormat {
  currency: string;
  minimumIntegerDigits: number;
  minimumFractionDigits: number;
}
