import _ from 'lodash';
import {localizationApi} from './localization-api';
import {format, isMatch, parse, parseISO} from 'date-fns';
import {formatInTimeZone} from 'date-fns-tz';
import {createStore} from '@stencil/store';

const LANGUAGE_QUERY_PARAM = 'language';
const LOCALE_DEFAULT = 'en-EN';

const NUMBER_FORMATS_DEFAULTS = {
  float: {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 1,
  },
  long: {
    minimumIntegerDigits: 1,
    maximumFractionDigits: 0,
  },
  currency: undefined,
};

const DATE_FORMATS_DEFAULTS = {
  date: 'dd/MM/yyyy',
  time: 'HH:mm.ss',
  hour: 'HH:mm',
  dateTime: 'dd/MM/yyyy HH:mm',
  timestamp: 'dd/MM/yyyy HH:mm:ss',
};


interface LocalizationServiceState {
  locale: string | undefined
  translations: Record<string, string> | undefined
  numberFormats?: {
    float: any;
    long: any;
    currency: any;
  },
  dateFormats: {
    date: string;
    time: string;
    hour: string;
    dateTime: string;
    timestamp: string;
  }
}

const NORMALIZED_DATE_FORMAT = 'yyyy-MM-dd';

export class LocalizationService {
  private readonly _state: LocalizationServiceState;

  constructor() {
    const {state} = createStore<LocalizationServiceState>({
      locale: LOCALE_DEFAULT,
      translations: undefined,
      numberFormats: NUMBER_FORMATS_DEFAULTS,
      dateFormats: DATE_FORMATS_DEFAULTS,
    });

    this._state = state;
  }

  get state(): DeepReadonly<LocalizationServiceState> {
    return this._state;
  }

  async init(language: string = this.getLanguageFromBrowserContext()) {
    let localizationResponse = await localizationApi.getLocalizations(language);

    if (localizationResponse.status === 'SUCCESS') {
      this._state.locale = language;
      this._state.translations = localizationResponse.keys;

      this._state.numberFormats = {
        float: localizationResponse.format.formats.float,
        long: localizationResponse.format.formats.long,
        currency: localizationResponse.format.formats.currency,
      };

      this._state.dateFormats = {
        date: localizationResponse.format.formats.date,
        time: localizationResponse.format.formats.time,
        hour: localizationResponse.format.formats.hour,
        dateTime: localizationResponse.format.formats.datetime,
        timestamp: localizationResponse.format.formats.timestamp,
      };
    } else {
      this._state.translations = undefined;
      this._state.numberFormats = NUMBER_FORMATS_DEFAULTS;
      this._state.dateFormats = DATE_FORMATS_DEFAULTS;
      this._state.locale = LOCALE_DEFAULT;
    }
  }

  private getLanguageFromBrowserContext() {
    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.has(LANGUAGE_QUERY_PARAM)) {
      return queryParameters.get(LANGUAGE_QUERY_PARAM)!;
    }

    return navigator.language || LOCALE_DEFAULT;
  }

  getTranslationFromText(text: TranslationText) {
    if ('translation' in text) {
      return text.translation;
    } else {
      return this.getTranslation(text.key, text.args || {});
    }
  }

  getTranslation(key: string | null | undefined, args: Record<string, string>): string {
    if (_.isNil(key)) {
      return '';
    }

    let translation = this._state.translations ? this._state.translations[key] : '';

    if (!translation) {
      console.warn(`Translation for key [${key}] is missing for language ${this._state.locale}. Using key as translation`);
      translation = key;
    }

    if (!_.isEmpty(args)) {
      Object.entries(args).forEach(([argKey, argValue]) =>
        translation = translation.replaceAll(`{${argKey}}`, argValue),
      );
    }

    return translation;
  }

  formatFloat(number: number): string {
    return number.toLocaleString(
      this._state.locale,
      {
        style: 'decimal',
        ...this._state.numberFormats?.float,
      },
    );
  }

  formatInteger(number: number): string {
    return number.toLocaleString(
      this._state.locale,
      {
        style: 'decimal',
        ...this._state.numberFormats?.long,
      },
    );
  }

  formatCurrency(amount: number): string {
    if (this._state.numberFormats?.currency) {
      return amount.toLocaleString(
        this._state.locale,
        {
          style: 'currency',
          ...this._state.numberFormats?.currency,
        },
      );
    } else {
      return this.formatFloat(amount);
    }
  }

  formatDate(date: Date | string): string {
    return format(this.parseDate(date), this._state.dateFormats.date);
  }

  formatPublicationDateEtka(date: string): string {
    let parsedDate = parse(date, 'ddMMyyyy', new Date());
    return format(parsedDate, this._state.dateFormats.date);
  }

  formatDateUTC(date: Date | string): string {
    return formatInTimeZone(date, 'UTC', this._state.dateFormats.dateTime);
  }

  formatTime(date: Date | string): string {
    return format(this.parseDate(date), this._state.dateFormats.time);
  }

  formatHour(date: Date | string): string {
    return format(this.parseDate(date), this._state.dateFormats.hour);
  }

  formatDateTime(date: Date | string): string {
    return format(this.parseDate(date), this._state.dateFormats.dateTime);
  }

  formatTimestamp(date: Date | string): string {
    return format(this.parseDate(date), this._state.dateFormats.timestamp);
  }

  formatTimestampUTC(date: Date | string): string {
    return formatInTimeZone(date, 'UTC', this._state.dateFormats.timestamp);
  }

  isDateString(date: string): boolean {
    return isMatch(date, this._state.dateFormats.date);
  }

  createNormalizedDateString(date: string): string {
    let parsedDate = parse(date, this._state.dateFormats.date, new Date());
    return format(parsedDate, NORMALIZED_DATE_FORMAT);
  }

  contextHelp(context: string): Promise<string> {
    return localizationApi.getContextHelp(this._state.locale!, context);
  }

  contextHelpUrl(context: string): string {
    return localizationApi.getContextHelpUrl(this._state.locale!, context);
  }

  private parseDate(date: Date | string): Date {
    return date instanceof Date ? date : parseISO(date);
  }
}

export const localizationService = new LocalizationService();

/**
 * Shorthand function for {@link LocalizationService#getTranslation}
 *
 * @param key   Translation key
 * @param args  Replacements for placeholders
 */
export function translate(key: string | null | undefined, args?: Record<string, string>): string;

/**
 * Shorthand function for {@link LocalizationService#getTranslationFromText}
 *
 * @param text   Translation text
 */
export function translate(text: TranslationText): string;

/**
 * Shorthand function for {@link LocalizationService#getTranslation} or {@link LocalizationService#getTranslationFromText}
 *
 * @param keyOrText   Translation key or text
 * @param args  Replacements for placeholders used when given a translation key
 */
export function translate(keyOrText: TranslationText | string | null | undefined, args: Record<string, string> = {}): string {
  if (isTranslationText(keyOrText)) {
    return localizationService.getTranslationFromText(keyOrText);
  } else {
    return localizationService.getTranslation(keyOrText, args);
  }
}

/**
 * Shorthand function for {@link LocalizationService#formatFloat}
 *
 * @param number Number
 */
export function formatFloat(number: number): string {
  return localizationService.formatFloat(number);
}

/**
 * Shorthand function for {@link LocalizationService#formatInteger}
 *
 * @param number Number
 */
export function formatInteger(number: number): string {
  return localizationService.formatInteger(number);
}

/**
 * Shorthand function for {@link LocalizationService#formatCurrency}
 *
 * @param amount Amount
 */
export function formatCurrency(amount: number): string {
  return localizationService.formatCurrency(amount);
}

/**
 * Shorthand function for {@link LocalizationService#formatDate}
 *
 * @param date Date
 */
export function formatDate(date: Date | string): string {
  return localizationService.formatDate(date);
}

/**
 * Shorthand function for {@link LocalizationService#formatPublicationDateEtka}
 *
 * @param date Date
 */
export function formatPublicationDateEtka(date: string): string {
  return localizationService.formatPublicationDateEtka(date);
}

/**
 * Shorthand function for {@link LocalizationService#formatDate}
 *
 * @param date Date
 */
export function formatDateUTC(date: Date | string): string {
  return localizationService.formatDateUTC(date);
}

/**
 * Shorthand function for {@link LocalizationService#formatTime}
 *
 * @param date Date
 */
export function formatTime(date: Date | string): string {
  return localizationService.formatTime(date);
}

/**
 * Shorthand function for {@link LocalizationService#formatHour}
 *
 * @param date Date
 */
export function formatHour(date: Date | string): string {
  return localizationService.formatHour(date);
}

/**
 * Shorthand function for {@link LocalizationService#formatDateTime}
 *
 * @param date Date
 */
export function formatDateTime(date: Date | string): string {
  return localizationService.formatDateTime(date);
}

/**
 * Shorthand function for {@link LocalizationService#formatTimestamp}
 *
 * @param date Date
 */
export function formatTimestamp(date: Date | string): string {
  return localizationService.formatTimestamp(date);
}

/**
 * Shorthand function for {@link LocalizationService#formatTimestampUTC}
 *
 * @param date Date
 */
export function formatTimestampUTC(date: Date | string): string {
  return localizationService.formatTimestampUTC(date);
}

export function isDateString(date: string): boolean {
  return localizationService.isDateString(date);
}

export function createNormalizedDateString(date: string): string {
  return localizationService.createNormalizedDateString(date);
}

/**
 * Shorthand function for {@link LocalizationService#contextHelp}
 *
 * @param context Context for help information
 */
export function contextHelp(context: string): Promise<string> {
  return localizationService.contextHelp(context);
}

/**
 * Shorthand function for {@link LocalizationService#contextHelpUrl}
 *
 * @param context Context for help information
 */
export function contextHelpUrl(context: string): string {
  return localizationService.contextHelpUrl(context);
}

/**
 * Interfaces for handling translation texts. This can be used if you have a mix of already translated texts and translation keys,
 * e.g. texts coming from the backend.
 */
export type TranslationText = TranslationTextWithKey | TranslationTextWithTranslation

export interface TranslationTextWithKey {
  /**
   * Translation key used when {@link TranslationText#translation} is not present.
   */
  key: string,

  /**
   * Translation arguments used when {@link TranslationText#translation} is not present.
   */
  args?: Record<string, string>,
}

export interface TranslationTextWithTranslation {
  /**
   * Already existing translation. If this is present, this value will be returned by the translation service.
   */
  translation: string;
}


/**
 * Type guard for {@link HistoryDataContainer}
 * @param o
 */
function isTranslationText(o: any): o is TranslationText {
  if (_.isNil(o) || typeof o !== 'object') {
    return false;
  }

  return ('key' in o && typeof o.key === 'string')
    || ('translation' in o && typeof o.translation === 'string');
}
